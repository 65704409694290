<template>
  <div id="printBarcode">
    <div class="ticket">
      <p class="centered text-uppercase">
        PENGAJUAN <br> {{ dataPrint.letter_name }}
      </p>
      <div class="card border-none my-card-print-barcode text-uppercase" style="border: none">
        <div class="card-body">
          <table border="0">
            <tr>
              <td>
                <b>Id Permohonan:</b> <br />
                {{ dataPrint.item.id }}
              </td>
            </tr>
            <tr>
              <td>
                <b>Nama Penduduk:</b>
                <br />
                {{ dataPrint.item.citizen_name }}
              </td>
            </tr>
            <tr>
              <td>
                <b>Tanggal:</b>
                <br />
                {{ dataPrint.item.registered_date_display }}
              </td>
            </tr>
          </table>
          <div class="class">
            <vue-qrcode
              :value="qrcodevalue"
              :color="{ dark: '#000000ff' }"
              type="image/png"
              style="height: 100px !important"
              @change="onDataUrlChange"
            />
            <img :src="dataUrl" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";

export default {
  props: {
    dataPrint: Object,
  },
  components: {
    VueQrcode,
  },
  data() {
    return {
      dataUrl: null,
      qrcodevalue: "google.com",
    };
  },
  methods: {
    onDataUrlChange(dataUrl) {
      console.log("change", dataUrl);
      this.dataUrl = dataUrl;
    },
  },
  watch: {
    dataPrint: function (newVal, oldVal) {
      console.log("watch data print", this.dataPrint);
      this.qrcodevalue = this.dataPrint.url_barcode;
    },
  },
};
</script>

<style scoped>
.my-card-print-barcode {
  /* width: 5.8cm; */
  font-family: monospace, monospace;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 250px;
  max-width: 250px;
  word-break: break-all;
  font-weight: 600;
  font-size: 20px;
}
</style>