<template>
  <div>
    <b-modal
      id="modal-family-member"
      size="lg"
      title="Tambah Anggota Keluarga"
      hide-footer
    >
      <b-form @submit.stop.prevent="modalFormOnsubmit">
        <label for="input-id_card_number"
          >NIK:</label
        >
        <b-form-group id="input-group-id_card_number">
          <b-form-input
            id="input-id_card_number"
            v-model="selected_family_form.id_card_number"
            placeholder="NIK"
          ></b-form-input>
          <small class="text-danger">{{
            selected_family_error.id_card_number
          }}</small>
        </b-form-group>
        <label for="input-name"
          >Nama:</label
        >
        <b-form-group id="input-group-name">
          <b-form-input
            id="input-name"
            v-model="selected_family_form.name"
            placeholder="Nama"
          ></b-form-input>
          <small class="text-danger">{{ selected_family_error.name }}</small>
        </b-form-group>

        <label for="input-name"
          >Status hubungan dalam keluarga:</label
        >
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="selected_family_form.shdk_id"
            :aria-describedby="ariaDescribedby"
            name="some-radios-family"
            value="1"
            >Kepala Keluarga</b-form-radio
          >
          <b-form-radio
            v-model="selected_family_form.shdk_id"
            :aria-describedby="ariaDescribedby"
            name="some-radios-family"
            value="2"
            >Istri</b-form-radio
          >
          <b-form-radio
            v-model="selected_family_form.shdk_id"
            :aria-describedby="ariaDescribedby"
            name="some-radios-family"
            value="3"
            >Anak</b-form-radio
          >
          <b-form-radio
            v-model="selected_family_form.shdk_id"
            :aria-describedby="ariaDescribedby"
            name="some-radios-family"
            value="4"
            >Saudara</b-form-radio
          >
          <small class="text-danger">{{ selected_family_error.shdk_id }}</small>
        </b-form-group>

        <b-button type="submit" size="lg" variant="primary">Simpan</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    familyCollection: Array,
    isAuth: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      selected_family_form: {
        citizen_id: "",
        name: "",
        id_card_number: "",
        shdk_id: "",
        shdk_name: "",
      },
      selected_family_error: {
        citizen_id: "",
        name: "",
        id_card_number: "",
        shdk_id: "",
        shdk_name: "",
      },
    };
  },

  methods: {
    async modalFormOnsubmit() {
      if (this.selected_family_form.shdk_id == 1) {
        this.selected_family_form.shdk_name = "Kepala Keluarga";
      } else if (this.selected_family_form.shdk_id == 2) {
        this.selected_family_form.shdk_name = "Istri";
      } else if (this.selected_family_form.shdk_id == 3) {
        this.selected_family_form.shdk_name = "Anak";
      } else if (this.selected_family_form.shdk_id == 4) {
        this.selected_family_form.shdk_name = "Saudara";
      }
      // Make Request
      let response = await module.submit(
        this.selected_family_form,
        "family-member"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.selected_family_error = validation.setValidationError(
          this.selected_family_error,
          response.error.errors
        );
      } else {
        // Success
        this.selected_family_error.citizen_id = "";
        this.selected_family_error.name = "";
        this.selected_family_error.shdk_id = "";
        this.selected_family_error.shdk_name = "";

        Swal.fire({
          title: "Berhasil",
          text: "Harap periksa kembali form",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$emit("successAddMember", this.selected_family_form);
      }
    },
  },
};
</script>

