<template>
  <v-app>
    <LayoutPrintBarcode :dataPrint="dataPrint" />
    <div
      style="
        background-image: url(/media/anjungan/BG-1280x1024.jpg);
        height: 100%;
        background-size: cover;
      "
      class="body-section"
    >
      <!-- Header::Start -->
      <Header2 :config="config" />
      <!-- Header::End -->
      <!-- <b-button @click="printBarcode">print</b-button> -->

      <!-- Menu::Start -->
      <div class="container-fluid px-40 mt-4" style="max-height: 590px">
        <!-- <div class="d-flex justify-content-end mt-5">
          <div>
            <h2
              @click="$router.push('/anjungan-mandiri/layanan-mandiri')"
              style="cursor: pointer"
            >
              <b-badge
                pill
                class="px-10 btn-cancel py-6"
                style="background: #dc10eb; color: white; font-size: 24px"
                >Kembali</b-badge
              >
            </h2>
          </div>
        </div> -->
        <!-- Breadcrumb::Start -->
        <div class="d-flex justify-content-between mt-10">
          <div>
            <h1 style="font-size: 35px; color: #bbc15d; font-weight: 600">
              <span @click="$router.push('/anjungan-mandiri/layanan-mandiri')">
                Layanan Mandiri</span
              >
              <span style="color: white"> > </span>
              <span style="font-size: 45px; color: white"> Surat Menyurat</span>
            </h1>
          </div>
          <div>
            <h2
              @click="$router.push('/anjungan-mandiri/layanan-mandiri')"
              style="cursor: pointer"
            >
              <b-badge
                pill
                class="px-10 btn-cancel py-6"
                style="background: #dc10eb; color: white; font-size: 24px"
                >Kembali</b-badge
              >
            </h2>
          </div>
        </div>
        <!-- Breadcrumb::End -->
        <div class="row">
          <!-- <div class="col-4" @click="$router.push('/surat-menyurat/keterangan-penduduk')"> -->
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-keterangan-penduduk')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Keterangan Penduduk</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-kelahiran')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Kelahiran</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-keterangan-kematian')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Kematian</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-ijin-usaha')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Ijin Usaha</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-domisili-usaha')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Domisili Usaha</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-keterangan-pindah')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Pindah</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-keterangan-jamkes')"
          >
            <!-- <div class="col-3 my-2" @click="$router.push('/surat-menyurat/keterangan-jamkes')"> -->
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Keterangan Jamkes</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-pengantar-ktp')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Pengantar KTP</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-keterangan-kurang-mampu')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Kurang Mampu</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-keterangan-kelakuan-baik')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Kelakuan Baik</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-keterangan-jkbm')"
          >
            <!-- <div class="col-3 my-2" @click="$router.push('/surat-menyurat/keterangan-jkbm')"> -->
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Keterangan JKBM</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-keterangan-belum-kawin')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Belum Kawin</span
                  >
                  <span class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-2"
            @click="$bvModal.show('modal-form-surat-umum')"
          >
            <div class="card my-card-letter">
              <div class="card-body py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="mt-2"
                    style="
                      font-size: 25px;
                      color: rgb(255, 255, 255);
                      font-weight: 600;
                    "
                    >Surat Umum</span
                  >
                  <b-button class="mr-2 icon-my-card-letter">
                    <img src="/media/anjungan/icon/email.png" height="65" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row d-none" style="position: relative; top: 9px">
          <div
            class="col-4 my-2"
            @click="$router.push('/surat-menyurat/keterangan-penduduk')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Keterangan-Penduduk.png"
              alt=""
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/surat-kelahiran')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Kelahiran.png"
              alt=""
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/surat-kematian')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Kematian.png"
              alt=""
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/ijin-usaha')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Ijin-Usaha.png"
              alt=""
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/domisili-usaha')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Domisili-Usaha.png"
              alt=""
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/surat-pindah')"
          >
            <img src="/media/anjungan/layer2/icon-3_Surat-Pindah.png" alt="" />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/keterangan-jamkes')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Pindah.png"
              alt="Jamkes"
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/pengantar-ktp')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Pindah.png"
              alt=" Pengantar KTP"
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/keterangan-kurang-mampu')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Pindah.png"
              alt="Kurang Mampu"
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/keterangan-kelakuan-baik')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Pindah.png"
              alt="Keterangan Kelakuan Baik"
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/keterangan-jkbm')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Pindah.png"
              alt="Keterangan JKBM"
            />
          </div>
          <div
            class="col-4"
            @click="$router.push('/surat-menyurat/keterangan-belum-kawin')"
          >
            <img
              src="/media/anjungan/layer2/icon-3_Surat-Pindah.png"
              alt="Keterangan Belum Kawin"
            />
          </div>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-end mr-25 mt-5">
        <div>
          <b-button
            type="button"
            class="ml-2 btn-cancel btn-lg"
            @click="$router.push('/anjungan-mandiri/layanan-mandiri')"
          >
            Kembali
          </b-button>
        </div>
      </div> -->
      <!-- Menu::End -->
      <!-- Footer::Start -->
      <Footer :config="config" />
      <!-- Footer::End -->

      <!-- Modal -->
      <ModalFormKeteranganPenduduk @successAddLetter="successAddLetter" />
      <ModalFormKeteranganJamkes @successAddLetter="successAddLetter" />
      <ModalFormKeteranganJKBM @successAddLetter="successAddLetter" />
      <ModalFormPengantarKTP @successAddLetter="successAddLetter" />
      <ModalFormKurangMampu @successAddLetter="successAddLetter" />
      <ModalFormKelakuanBaik @successAddLetter="successAddLetter" />
      <ModalFormSuratKelahiran @successAddLetter="successAddLetter" />
      <ModalFormSuratKematian @successAddLetter="successAddLetter" />
      <ModalFormIjinUsaha @successAddLetter="successAddLetter" />
      <ModalFormDomisiliUsaha @successAddLetter="successAddLetter" />
      <ModalFormSuratPindah @successAddLetter="successAddLetter" />
      <ModalFormSuratBelumKawin @successAddLetter="successAddLetter" />
      <ModalFormSuratUmum @successAddLetter="successAddLetter" />
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import {
  setConfiguration,
  getConfiguration,
} from "@/core/services/jwt.service.js";

import ModalFormKeteranganPenduduk from "@/view/components/surat-menyurat/ModalFormKeteranganPenduduk.vue";
import ModalFormKeteranganJamkes from "@/view/components/surat-menyurat/ModalFormKeteranganJamkes.vue";
import ModalFormKeteranganJKBM from "@/view/components/surat-menyurat/ModalFormKeteranganJKBM.vue";
import ModalFormPengantarKTP from "@/view/components/surat-menyurat/ModalFormPengantarKTP.vue";
import ModalFormKurangMampu from "@/view/components/surat-menyurat/ModalFormKurangMampu.vue";
import ModalFormKelakuanBaik from "@/view/components/surat-menyurat/ModalFormKelakuanBaik.vue";
import ModalFormSuratKelahiran from "@/view/components/surat-menyurat/ModalFormSuratKelahiran.vue";
import ModalFormSuratKematian from "@/view/components/surat-menyurat/ModalFormSuratKematian.vue";
import ModalFormIjinUsaha from "@/view/components/surat-menyurat/ModalFormIjinUsaha.vue";
import ModalFormDomisiliUsaha from "@/view/components/surat-menyurat/ModalFormDomisiliUsaha.vue";
import ModalFormSuratPindah from "@/view/components/surat-menyurat/ModalFormSuratPindah.vue";
import ModalFormSuratBelumKawin from "@/view/components/surat-menyurat/ModalFormSuratBelumKawin.vue";
import ModalFormSuratUmum from "@/view/components/surat-menyurat/ModalFormSuratUmum.vue";
import Header2 from "@/view/components/anjungan-mandiri/Header2.vue";
import Footer from "@/view/components/anjungan-mandiri/Footer.vue";
import LayoutPrintBarcode from "@/view/components/surat-menyurat/PrintBarcode2.vue";

export default {
  components: {
    ModalFormKeteranganPenduduk,
    ModalFormKeteranganJamkes,
    ModalFormKeteranganJKBM,
    ModalFormPengantarKTP,
    ModalFormKurangMampu,
    ModalFormKelakuanBaik,
    ModalFormSuratKelahiran,
    ModalFormSuratKematian,
    ModalFormIjinUsaha,
    ModalFormDomisiliUsaha,
    ModalFormSuratPindah,
    ModalFormSuratBelumKawin,
    ModalFormSuratUmum,
    Header2,
    Footer,
    LayoutPrintBarcode,
  },
  data() {
    return {
      config: getConfiguration() == null ? {} : getConfiguration(),
      interval: null,
      time: null,
      dataPrint: {
        item: {
          id: "",
          citizen_name: "",
          registered_date_display: "",
        },
      },
    };
  },
  methods: {
    successAddLetter(data) {
      this.dataPrint = data;
      setTimeout(() => {
        this.printBarcode();
      }, 1000);
    },
    async printBarcode() {
      // Pass the element id here
      // await this.$htmlToPaper('printBarcode');
      setTimeout(() => {
        window.print();
      }, 3000);
      console.log("print barcode");
    },
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    openWindow(url, name, props) {
      let windowRef = null;
      windowRef = window.open(url, name, props);

      return windowRef;
    },
  },
  mounted() {
    this.getSetting();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style>
@media print {
  @page {
    size: 10cm 10cm;
  }

  .body-section {
    display: none;
  }

  body {
    background-color: white;
  }

  #printBarcode {
    display: block !important;
    size: 10cm 10cm;
  }
}

#printBarcode {
  display: none;
}
</style>

<style scoped>
.my-card-letter {
  /* background-image: linear-gradient(to top, #38c762, #088346); */
  /* border-color: transparent; */
  border-color: green;
  background-image: linear-gradient(to top, #c8c753, #4eaa79);
  /* background: #ffffff; */
  border-radius: 20px;
  cursor: pointer;
  height: 102px;
}

.my-card-letter:hover {
  box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  -webkit-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  -moz-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
}

.icon-my-card-letter {
  width: 72px;
  height: 69px;
  border-radius: 46px;
  padding: 0;
  /* background: #ffffff; */
  background: none;
  /* border-color: #48aa7b; */
  border-color: transparent;
}
</style>
