<template>
  <div>
    <b-modal
      id="modal-form-domisili-usaha"
      size="xl"
      hide-footer
      @hide="reset"
      @show="reset"
      content-class="my-custom-modal"
      no-close-on-esc
      no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <HeaderModalFormLetter
          :title="'Form Surat Domisili Usaha'"
          :close="close"
        />
      </template>
      <template #modal-title>
        <b style="font-size: 30px">Form Surat Domisili Usaha</b>
      </template>
      <div style="overflow-y: scroll; height: 440px">
        <b-form @submit.stop.prevent="formOnsubmit" class="px-5">
          <div class="row mt-0">
            <div class="col py-0">
              <b-form-group id="input-group-id_card_number">
                <label
                  for="input-id_card_number"
                  style="font-size: 22px; font-weight: 600"
                  >NIK:</label
                >
                <b-form-input
                  id="input-id_card_number"
                  v-model="IdCardNumber"
                  placeholder="NIK"
                  size="lg"
                  style="font-size: 25px"
                  ref="idCardNumber"
                  autocomplete="off"
                ></b-form-input>
                <small
                  class="text-danger"
                  style="
                    font-size: 20px;
                    font-weight: bolder;
                    color: red !important;
                  "
                  >{{ error.id_card_number }}</small
                >
              </b-form-group>
            </div>
            <div class="col py-0">
              <b-form-group id="input-group-family_number">
                <label
                  for="input-family_number"
                  style="font-size: 22px; font-weight: 600"
                  >No. KK:</label
                >
                <b-form-input
                  id="input-family_number"
                  v-model="FamilyNumber"
                  placeholder="No. KK"
                  size="lg"
                  style="font-size: 25px"
                  ref="FamilyNumber"
                  autocomplete="off"
                ></b-form-input>
                <small
                  class="text-danger"
                  style="
                    font-size: 20px;
                    font-weight: bolder;
                    color: red !important;
                  "
                  >{{ error.family_number }}</small
                >
              </b-form-group>
            </div>
            <div class="col py-0">
              <b-form-group id="input-group-phone">
                <label
                  for="input-phone"
                  class="label-bold"
                  style="font-size: 22px; font-weight: 600"
                  >No. WA
                  <em class="text-muted warning-message"
                    >(Tidak Wajib Diisi)</em
                  ></label
                >
                <b-form-input
                  id="input-phone"
                  v-model="form.phone"
                  placeholder="62851234......"
                  style="font-size: 25px"
                  autocomplete="off"
                ></b-form-input>
                <small
                  class="text-danger"
                  style="
                    font-size: 20px;
                    font-weight: bolder;
                    color: red !important;
                  "
                  >{{ error.phone }}</small
                >
              </b-form-group>
            </div>
            <div class="col py-0">
              <b-form-group id="input-group-purpose">
                <label
                  for="input-purpose"
                  style="font-size: 22px; font-weight: 600"
                  >Keperluan:</label
                >
                <b-form-input
                  id="input-purpose"
                  v-model="form.purpose"
                  placeholder="Keperluan"
                  size="lg"
                  style="font-size: 25px"
                  autocomplete="off"
                ></b-form-input>
                <small
                  class="text-danger"
                  style="
                    font-size: 20px;
                    font-weight: bolder;
                    color: red !important;
                  "
                  >{{ error.purpose }}</small
                >
              </b-form-group>
            </div>
          </div>
          <div class="row mt-0">
            <div class="col py-0">
              <label
                for="input-start-date"
                style="font-size: 22px; font-weight: 600"
                >Usaha Mulai Sejak:</label
              >
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="form.start_date"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <b-input-group>
                    <b-input-group-prepend>
                      <button
                        class="btn btn-secondary"
                        type="button"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="fas fa-calendar"></i>
                      </button>
                    </b-input-group-prepend>
                    <b-form-input
                      id="input-trigger-modal-patient"
                      v-model="form.start_date"
                      placeholder="Tanggal (YYYY-MM-DD)"
                      readonly
                      size="lg"
                      style="font-size: 25px"
                    >
                    </b-form-input>
                  </b-input-group>
                </template>
                <v-date-picker
                  v-if="modal2"
                  v-model="form.start_date"
                  locale="id"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(form.start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <small
                class="text-danger"
                style="
                  font-size: 20px;
                  font-weight: bolder;
                  color: red !important;
                "
                >{{ error.start_date }}</small
              >
            </div>
            <div class="col py-0">
              <b-form-group id="input-group-name">
                <label
                  for="input-name"
                  style="font-size: 22px; font-weight: 600"
                  >Nama Usaha:</label
                >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Nama Usaha"
                  size="lg"
                  style="font-size: 25px"
                  autocomplete="off"
                ></b-form-input>
                <small
                  class="text-danger"
                  style="
                    font-size: 20px;
                    font-weight: bolder;
                    color: red !important;
                  "
                  >{{ error.name }}</small
                >
              </b-form-group>
            </div>
            <div class="col py-0">
              <b-form-group id="input-group-address">
                <label
                  for="input-address"
                  style="font-size: 22px; font-weight: 600"
                  >Alamat Usaha:</label
                >
                <b-form-textarea
                  id="input-address"
                  v-model="form.address"
                  placeholder="Alamat Usaha"
                  rows="2"
                  max-rows="8"
                  style="font-size: 25px"
                ></b-form-textarea>
                <small
                  class="text-danger"
                  style="
                    font-size: 20px;
                    font-weight: bolder;
                    color: red !important;
                  "
                  >{{ error.address }}</small
                >
              </b-form-group>
            </div>
          </div>

          <!-- <b-form-group id="input-group-hamlet_id">
          <label for="input-hamlet_id" style="font-size: 22px; font-weight: 600"
            >Pilih No. WA Ketua RW:</label
          >
            <treeselect
              v-model="form.hamlet_id"
              :multiple="false"
              placeholder="Pilih No. WA Ketua RW"
              :options="hamlets"
              style="font-size: 25px"
            />
            <small
              class="text-danger"
              style="
                font-size: 20px;
                font-weight: bolder;
                color: red !important;
              "
              >{{ error.hamlet_id }}</small
            >
          </b-form-group> -->
<div class="d-flex mt-auto" style="position: absolute; bottom: 20px">
          <b-button
            type="submit"
            size="lg"
            variant="white"
            class="mt-10"
            style="
              height: 58px;
              width: 125px;
              font-size: 23px;
              font-weight: 600;
              background: #bbc15d;
              border-color: #bbc15d;
            "
            >Kirim</b-button
          >
          <b-button
            type="button"
            class="ml-2 mt-10"
            variant="secondary"
            style="
              height: 58px;
              width: 125px;
              font-size: 23px;
              font-weight: 600;
            "
            size="lg"
            @click="$bvModal.hide('modal-form-domisili-usaha')"
          >
            Batal
          </b-button>
</div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import HeaderModalFormLetter from "@/view/components/anjungan-mandiri/HeaderModalFormLetter.vue";

export default {
  components: {
    HeaderModalFormLetter,
  },
  data() {
    return {
      config: {},
      interval: null,
      time: null,
      timeout: null,
      timeout2: null,
      modal2: false,
      form: {
        title: "Surat Domisili Usaha",
        letter_type_id: 7,
        resource: 2,
        phone: ""
      },
      error: {
        id_card_number: "",
        family_number: "",
        hamlet_id: "",
        start_date: "",
        name: "",
        purpose: "",
        address: "",
        phone: "",
      },
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    async getHamletHeadOption() {
      let response = await module.setTreeSelectHamletHead("hamlets");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.hamlets = response.data;
        this.hamlets.unshift({
          label: "Pilih No. WA Ketua RW",
          id: "",
          isDisabled: true,
        });
      }
    },
    reset() {
      this.form.id_card_number = "";
      this.form.family_number = "";
      this.form.hamlet_id = "";
      //
      this.form.name = "";
      this.form.purpose = "";
      this.form.address = "";
      this.form.start_date = "";

      this.error.id_card_number = "";
      this.error.family_number = "";
      this.error.hamlet_id = "";
      //
      this.error.name = "";
      this.error.purpose = "";
      this.error.address = "";
      this.error.start_date = "";

      for (const [key] of Object.entries(this.error)) {
        this.error[key] = "";
      }
      for (const [key] of Object.entries(this.form)) {
        if (key != "resource" && key != "title" && key != "letter_type_id") {
          this.form[key] = "";
        }
      }

      setTimeout(() => {
        if (this.$refs.hasOwnProperty("idCardNumber")) {
          if (typeof this.$refs.idCardNumber != "undefined") {
            this.$refs.idCardNumber.$el.value = "";
          }
        }
        if (this.$refs.hasOwnProperty("FamilyNumber")) {
          if (typeof this.$refs.FamilyNumber != "undefined") {
            this.$refs.FamilyNumber.$el.value = "";
          }
        }
      }, 500);
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(
        this.form,
        "bussiness-certificate-no-auth"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        // Swal.fire(response.success.title, response.success.message, "success");
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.reset();
        this.$bvModal.hide("modal-form-domisili-usaha");
        let item = response.success.item.original;
        let data = {
          item: item,
          url_barcode:
            window.location.host + "/#/bussiness-certificate/detail/" + item.id,
          letter_name: "Surat Keterangan Domisili Usaha",
        };
        this.$emit("successAddLetter", data);
      }
    },
    async checkIdCardNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(
        this.form,
        "check-id-card-number"
      );
      // Check Response
      if (response.state == "error") {
        Swal.fire(
          "",
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.",
          "error"
        );
        this.error.id_card_number =
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.";
      } else {
        this.error.id_card_number = "";
        this.form.phone = response.success.data.phone;
      }
    },
    async checkFamilyNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(
        this.form,
        `check-compatibility/${this.form.id_card_number}/${this.form.family_number}`
      );
      console.log("resp", response);
      // Check Response
      if (response.success == "") {
        this.error.family_number =
          "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.";
        Swal.fire(
          "",
          "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.",
          "error"
        );
      } else {
        this.error.family_number = "";
      }
    },
  },
  computed: {
    IdCardNumber: {
      get() {
        this.error.id_card_number = "";
        return this.form.id_card_number;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.form.id_card_number = val;
          if (this.form.id_card_number.length >= 15) {
            this.checkIdCardNumber();
          }
        }, 500);
      },
    },
    FamilyNumber: {
      get() {
        this.error.family_number = "";
        return this.form.family_number;
      },
      set(val) {
        if (this.timeout2) clearTimeout(this.timeout2);
        this.timeout2 = setTimeout(() => {
          this.form.family_number = val;
          if (this.form.family_number.length >= 15) {
            this.checkFamilyNumber();
          }
        }, 500);
      },
    },
  },
  mounted() {
    this.getSetting();
    this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.form-control-lg {
  height: 61px;
}
.form-control{
  border-color: rgb(29, 172, 116);
}

.form-control:focus{
  border-color: #bbc15d;
}
</style>

<style>
/* .vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 50px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 50px !important;
}
.vue-treeselect__label {
  color: black !important;
}
.vue-treeselect__menu {
  color: black !important;
  font-size: 30px;
} */
</style>