import { render, staticRenderFns } from "./ModalFormKeteranganJKBM.vue?vue&type=template&id=04ca8c26&scoped=true"
import script from "./ModalFormKeteranganJKBM.vue?vue&type=script&lang=js"
export * from "./ModalFormKeteranganJKBM.vue?vue&type=script&lang=js"
import style0 from "./ModalFormKeteranganJKBM.vue?vue&type=style&index=0&id=04ca8c26&prod&scoped=true&lang=css"
import style1 from "./ModalFormKeteranganJKBM.vue?vue&type=style&index=1&id=04ca8c26&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04ca8c26",
  null
  
)

export default component.exports